import { siteConfig } from '@/config/site.config'
import Image from 'next/image'
import Link from 'next/link'
import { getLangCode, setLangCode } from '../i18n/lang-context'
import { useScreen } from '../utils/windowSize'

export const LogoHorizontal = ({ logo, onClick, langCode }: { logo?: { header: string, headerWhite: string }, onClick?: () => void, langCode: string }) => {

  setLangCode(langCode)

  const screen = useScreen()

  return (
    <Link href={'/' + getLangCode()} onClick={onClick}>
      <Image alt={siteConfig.appName}
        src={logo ? logo.headerWhite : siteConfig.logo.headerWhite}
        width={0}
        height={0}
        sizes='100vw'
        priority={true}
        className='hidden transition-all hover:scale-110 dark:block'
        style={{
          height: screen.md ? 40 : 32,
          width: 'auto'
        }} />
      <Image alt={siteConfig.appName}
        src={logo ? logo.header : siteConfig.logo.header}
        width={0}
        height={0}
        sizes='100vw'
        priority={true}
        className='block transition-all hover:scale-110 dark:hidden'
        style={{
          height: screen.md ? 40 : 32,
          width: 'auto'
        }} />
    </Link>
  )
}
