'use client'

import { cn } from '@/lib/utils/utils'
import { useCallback, useEffect, useState } from 'react'
import { LangMenu } from '../i18n/lang-toggle'
import { Nav } from '../layout/nav'
import { NavItem } from '../layout/nav-item'
import { ThemeToggle } from '../theme/theme-toggle'
import { Flex } from '../ui/flex'
import { ScrollDirection, useScroll } from '../utils/windowScroll'
import { useScreen } from '../utils/windowSize'
import { LogoHorizontal } from './logo'
import { Menu } from './menu'

export function Header({ navItems, actionButton, logo, langCode }: { navItems?: NavItem[], actionButton?: JSX.Element, logo?: { header: string, headerWhite: string }, langCode: string }) {
  const screen = useScreen()
  const { scrollDir, scrollY } = useScroll(1)
  const [shown, setShown] = useState(false)
  const [initialRender, setInitialRender] = useState(true)
  let onMouseLeaveTimeoutFunc: ReturnType<typeof setTimeout> | null = null

  const show = useCallback(() => shown || scrollDir === ScrollDirection.up, [shown, scrollDir])

  const handleMouseLeave = () => {
    if (onMouseLeaveTimeoutFunc) clearTimeout(onMouseLeaveTimeoutFunc)
    onMouseLeaveTimeoutFunc = setTimeout(() => setShown(false), 0)
  }

  const handleMouseEnter = () => {
    if (!screen.md) return
    if (onMouseLeaveTimeoutFunc) {
      clearTimeout(onMouseLeaveTimeoutFunc)
      onMouseLeaveTimeoutFunc = null
    }
    setShown(true)
  }

  useEffect(() => {
    return () => {
      if (onMouseLeaveTimeoutFunc) clearTimeout(onMouseLeaveTimeoutFunc)
    }
  }, [])

  useEffect(() => {
    if (initialRender) {
      if (scrollY > 0) {
        setShown(true)
      }
      setInitialRender(false)
    }
  }, [scrollY, initialRender])

  const headerTransform = {
    transform: `translateY(-${show() ? 0 : screen.md ? 80 : 60}px)`,
    opacity: show() ? 1 : 0,
    backgroundColor: show() || scrollY > 0 ? 'var(--background)' : 'transparent'
  }

  return (
    <>
      <div
        className='fixed left-0 top-0 z-50 h-16 w-full md:h-20'
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        <div
          className={cn('w-full transition-all duration-300', show() || scrollY > 0 ? 'shadow-header' : '')}
          style={headerTransform}
        >
          <header className='border-1 m-auto flex h-16 w-full max-w-7xl items-center justify-between gap-6 p-4 md:h-20'>
            <LogoHorizontal logo={logo} langCode={langCode} />
            <div className='grow' />
            {screen.md && <Nav navItems={navItems} className='lg:ml-[84px]' />}
            {screen.md && <Flex className='items-center gap-10'>
              {screen.md && <Flex className='items-center gap-6'><ThemeToggle /><LangMenu /></Flex>}
              {actionButton}
              {/* {!connected() && (<LoginDialogTrigger />)} */}
            </Flex>}
            {!screen.md && <Menu navItems={navItems} logo={logo} langCode={langCode} />}
          </header>
        </div>
      </div>
      <div id='HEADER_PLACEHOLDER' className='h-16 md:h-20' />
    </>
  )
}
