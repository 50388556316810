'use client'

import { cn } from '@/lib/utils/utils'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Flex, FlexCol } from '../ui/flex'
import { getLangCode, LangCode, LANGS, setLangCode } from './lang-context'

export function LangToggle() {

  const [currentLang, setCurrentLang] = useState('')
  const router = useRouter()
  const pathname = usePathname()

  useEffect(() => {
    setCurrentLang(getLangCode())
  }, [])

  const changeLocale = (newLocale: string) => {
    const locales = Object.keys(LANGS).join('|')
    const localeRegex = new RegExp(`^/(${locales})`)
    const localeMatch = pathname.match(localeRegex)
    router.push(localeMatch ?
      pathname.replace(localeRegex, `/${newLocale}`) :
      `/${newLocale}${pathname}`
    )
  }

  const pickLang = (newLangCode: LangCode) => {
    setLangCode(newLangCode)
    setCurrentLang(getLangCode())
    changeLocale(newLangCode)
  }

  return (<div
    className='cursor-pointer text-sm opacity-40 transition-all hover:opacity-100'
    onClick={() => pickLang(getLangCode() === 'fr' ? 'en' : 'fr')}>
    {currentLang.toUpperCase()}
  </div>)
}

export function LangMenu() {

  const [expanded, setExpanded] = useState(false)
  const router = useRouter()
  const pathname = usePathname()

  const changeLocale = (newLocale: string) => {
    const locales = Object.keys(LANGS).join('|')
    const localeRegex = new RegExp(`^/(${locales})`)
    const localeMatch = pathname.match(localeRegex)
    router.push(localeMatch ?
      pathname.replace(localeRegex, `/${newLocale}`) :
      `/${newLocale}${pathname}`
    )
  }

  const pickLang = (newLangCode: LangCode) => {
    setLangCode(newLangCode)
    setExpanded(false)
    changeLocale(newLangCode)
  }

  return (
    <div className='relative select-none'>
      <Flex className='cursor-pointer items-center gap-0 text-sm opacity-40 transition-all hover:opacity-100' onClick={() => setExpanded(exp => !exp)}>
        {getLangCode().toUpperCase()}
        <div id='ARROW' className='text-lg font-semibold opacity-70'>
          <MdKeyboardArrowDown size={16} className={cn('transition-all', expanded ? 'rotate-180' : '')} />
        </div>
      </Flex>
      {typeof window !== 'undefined' && <FlexCol className={cn('absolute left-[-25%] top-full mt-2 w-fit origin-top items-center gap-0 overflow-hidden rounded-md bg-white/90 p-1 text-sm shadow-sm transition-all dark:bg-gray-800/90 dark:shadow-header', expanded ? 'scale-y-100' : 'scale-y-0')}>
        <div className={cn('p-1 transition-all hover:opacity-100', getLangCode() === 'fr' ? 'font-medium' : 'cursor-pointer font-normal opacity-40')} onClick={() => pickLang('fr')}>FR</div>
        <div className={cn('p-1 transition-all hover:opacity-100', getLangCode() === 'en' ? 'font-medium' : 'cursor-pointer font-normal opacity-40')} onClick={() => pickLang('en')}>EN</div>
      </FlexCol>}
    </div>
  )
}

export default LangMenu
