import Link from 'next/link'

import { NavItem } from '@/components/layout/nav-item'
import { cn } from '@/lib/utils/utils'
import { useI18nValue } from '../i18n/lang-context'
import { Button } from '../ui/button'

export function Nav({ navItems = [], className = '' }: { navItems?: NavItem[], className?: string }) {

  const i18nValue = useI18nValue()

  return (
    <nav className={cn('flex h-full gap-4', className)}>
      {navItems?.map((item, index) => item.href && (
        <Link
          key={index}
          href={item.href}
          className='flex items-center'
        >
          <Button variant={'link'} className={cn(
            'text-sm font-medium text-foreground opacity-75 md:text-[14px] lg:text-[16px]'
          )}>{typeof item.title === 'string' ? item.title : i18nValue(item.title)}</Button>
        </Link>
      ))}
    </nav>
  )
}
