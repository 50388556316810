'use client'

import { cn } from '@/lib/utils/utils'
import { useMounted } from '@/lib/utils/utils-client'
import { useTheme } from 'next-themes'
import Link from 'next/link'
import { useState } from 'react'
import { MdClose, MdOutlineMenu } from 'react-icons/md'
import { useI18nValue } from '../i18n/lang-context'
import { LangToggle } from '../i18n/lang-toggle'
import { NavItem } from '../layout/nav-item'
import { ThemeToggle } from '../theme/theme-toggle'
import { Button } from '../ui/button'
import { Flex, FlexCol } from '../ui/flex'
import { useScreen } from '../utils/windowSize'
import { DDAction } from './dd/dd-action'
import { LogoHorizontal } from './logo'


export function Menu({ navItems = [], logo, langCode }: { navItems?: NavItem[], logo?: { header: string, headerWhite: string }, langCode: string }) {

  const screen = useScreen()
  const { theme } = useTheme()
  const i18nValue = useI18nValue()
  const [shown, setShown] = useState(false)

  const mounted = useMounted()
  if (!mounted) return null

  return (
    <>
      <MdOutlineMenu size={32} className='text-primary' onClick={() => setShown(s => !s)} />
      <div className='fixed right-0 top-0 z-40 flex h-screen w-full flex-col bg-background transition-all' style={{
        transform: 'translateX(' + (shown ? '0' : '100%') + ')'
      }}>
        <Flex className='h-16 items-center justify-between p-4'>
          <LogoHorizontal logo={logo} onClick={() => setShown(false)} langCode={langCode} />
          <MdClose size={32} className='text-primary' onClick={() => setShown(false)} />
        </Flex>
        <FlexCol className='gap-6 py-8 pt-12' onClick={() => setShown(false)}>
          {navItems?.map((item, index) => item.href && (
            <Link key={index} href={item.href} className='flex w-full items-center justify-center px-4'>
              <Button variant={'link'} className={cn('text-[18px] font-medium text-primary dark:text-primaryBright')} onClick={() => setShown(false)}>
                {typeof item.title === 'string' ? item.title : i18nValue(item.title)}
              </Button>
            </Link>
          ))}
          <Flex className='w-full items-center justify-center gap-6'><ThemeToggle /><LangToggle /></Flex>
        </FlexCol>
        <div className='w-full px-4 text-base'>
          <DDAction className='w-full' />
        </div>
      </div>
    </>
  )
}
